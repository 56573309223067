import React, { useEffect } from 'react';
import Header from '../components/resuableComponents/Header';
import Footer from '../components/resuableComponents/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { addCartRow, removeSelectedWhish } from '../reducers/UiReducer';

const WhislistPage = () => {
	const { whishlist } = useSelector((state) => state.ui);
	const dispatch = useDispatch();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Header />
			<main className='main'>
				<div className='page-header breadcrumb-wrap'>
					<div className='container'>
						<div className='breadcrumb'>
							<a href='index.html' rel='nofollow'>
								Home
							</a>
							<span></span> Shop
							<span></span> Wishlist
						</div>
					</div>
				</div>
				<section className='mt-50 mb-50'>
					<div className='container'>
						<div className='row'>
							<div className='col-12'>
								<div className='table-responsive'>
									<table className='table shopping-summery text-center'>
										<thead>
											<tr className='main-heading'>
												<th scope='col' colspan='2'>
													Product
												</th>
												<th scope='col'>Price</th>
												<th scope='col'>Stock Status</th>
												<th scope='col'>Action</th>
												<th scope='col'>Remove</th>
											</tr>
										</thead>
										<tbody>
											{whishlist?.map((i) => {
												return (
													<tr>
														<td className='image product-thumbnail'>
															<img src={i.imgpath} alt='#' />
														</td>
														<td className='product-des product-name'>
															<h5 className='product-name'>
																<a href=''>{i.name}</a>
															</h5>
															<p className='font-xs'>{i.desc}</p>
														</td>
														<td className='price' data-title='Price'>
															<span>₹{i.price_per}</span>
														</td>
														<td className='text-center' data-title='Stock'>
															<span className='color3 font-weight-bold'>
																In Stock
															</span>
														</td>
														<td className='text-right' data-title='Cart'>
															<button
																className='btn btn-sm'
																onClick={() => {
																	dispatch(addCartRow(i));
																	dispatch(removeSelectedWhish(i));
																}}>
																<i className='fi-rs-shopping-bag mr-5'></i>Add
																to cart
															</button>
														</td>
														<td className='action' data-title='Remove'>
															<a
																onClick={() =>
																	dispatch(removeSelectedWhish(i))
																}>
																<i className='fi-rs-trash'></i>
															</a>
														</td>
													</tr>
												);
											})}

											{/* <tr>
												<td className='image'>
													<img src='assets/imgs/shop/product-2-1.jpg' alt='#' />
												</td>
												<td className='product-des'>
													<h5 className='product-name'>
														<a href='shop-product-right.html'>
															Amazon Essentials Women's Tank
														</a>
													</h5>
													<p className='font-xs'>
														Sit at ipsum amet clita no est,
														<br /> sed amet sadipscing et gubergren
													</p>
												</td>
												<td className='price' data-title='Price'>
													<span>$75.00 </span>
												</td>
												<td className='text-center' data-title='Stock'>
													<span className='color3 font-weight-bold'>
														In Stock
													</span>
												</td>
												<td className='text-right' data-title='Cart'>
													<button className='btn btn-sm'>
														<i className='fi-rs-shopping-bag mr-5'></i>Add to
														cart
													</button>
												</td>
												<td className='action' data-title='Remove'>
													<a href='#'>
														<i className='fi-rs-trash'></i>
													</a>
												</td>
											</tr>
											<tr>
												<td className='image'>
													<img src='assets/imgs/shop/product-3-1.jpg' alt='#' />
												</td>
												<td className='product-des'>
													<h5 className='product-name'>
														<a href='shop-product-right.html'>
															Amazon Brand - Daily Ritual Women's Jersey{' '}
														</a>
													</h5>
													<p className='font-xs'>
														Erat amet et et amet diam et et.
														<br /> Justo amet at dolore
													</p>
												</td>
												<td className='price' data-title='Price'>
													<span>$62.00 </span>
												</td>
												<td className='text-center' data-title='Stock'>
													<span className='text-danger font-weight-bold'>
														Out of stock
													</span>
												</td>
												<td className='text-right' data-title='Cart'>
													<button className='btn btn-sm btn-secondary'>
														<i className='fi-rs-headset mr-5'></i>Contact Us
													</button>
												</td>
												<td className='action' data-title='Remove'>
													<a href='#'>
														<i className='fi-rs-trash'></i>
													</a>
												</td>
											</tr> */}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			<Footer />
		</>
	);
};

export default WhislistPage;
