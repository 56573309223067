import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LandingPage from '../pages/LandingPage';
import LoginPage from '../pages/LoginPage';
import ProductDetailsPage from '../pages/ProductDetailsPage';
import { CartPage } from '../pages/CartPage';
import WhislistPage from '../pages/WhislistPage';
import MyAccount from '../pages/MyAccount';
import ContactPage from '../pages/ContactPage';
import CheckoutPage from '../pages/CheckoutPage';
import NotFoundPage from '../pages/NotFoundPage';

const MainRouter = () => {
	return (
		<div>
			<Routes>
				<Route path='/' exact element={<LandingPage />} />
				<Route path='/login' exact element={<LoginPage />} />
				<Route
					path='/productdetails/:name'
					exact
					element={<ProductDetailsPage />}
				/>
				<Route path='/cart' exact element={<CartPage />} />
				<Route path='/whishlist' exact element={<WhislistPage />} />
				<Route path='/dashboard' exact element={<MyAccount />} />
				<Route path='/contact' exact element={<ContactPage />} />
				<Route path='/checkout' exact element={<CheckoutPage />} />
				<Route path='*' exact element={<NotFoundPage />} />
			</Routes>
		</div>
	);
};

export default MainRouter;
