import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import bag from '../../assets/images/bag.jpg';

const MonthlyCarousel = () => {
	const products = [
		{
			id: 1,
			name: 'Lorem ipsum dolor',
			price: 238.85,
			oldPrice: 245.8,
			badge: 'Hot',
			img1: 'assets/imgs/shop/product-2-1.jpg',
			img2: 'assets/imgs/shop/product-2-2.jpg',
		},
		{
			id: 2,
			name: 'Aliquam posuere',
			price: 173.85,
			oldPrice: 185.8,
			badge: 'New',
			img1: 'assets/imgs/shop/product-4-1.jpg',
			img2: 'assets/imgs/shop/product-4-2.jpg',
		},
		{
			id: 3,
			name: 'Sed dapibus orci',
			price: 215.85,
			oldPrice: 235.8,
			badge: 'Sale',
			img1: 'assets/imgs/shop/product-15-1.jpg',
			img2: 'assets/imgs/shop/product-15-2.jpg',
		},
		{
			id: 4,
			name: 'Sed dapibus orci',
			price: 215.85,
			oldPrice: 235.8,
			badge: 'Sale',
			img1: 'assets/imgs/shop/product-15-1.jpg',
			img2: 'assets/imgs/shop/product-15-2.jpg',
		},
		{
			id: 5,
			name: 'Sed dapibus orci',
			price: 215.85,
			oldPrice: 235.8,
			badge: 'Sale',
			img1: 'assets/imgs/shop/product-15-1.jpg',
			img2: 'assets/imgs/shop/product-15-2.jpg',
		},
		{
			id: 6,
			name: 'Sed dapibus orci',
			price: 215.85,
			oldPrice: 235.8,
			badge: 'Sale',
			img1: 'assets/imgs/shop/product-15-1.jpg',
			img2: 'assets/imgs/shop/product-15-2.jpg',
		},
		// Add more products here
	];

	return (
		<section className='section-padding'>
			<div className='container wow fadeIn animated'>
				<Swiper
					modules={[Navigation, Pagination, Autoplay]}
					navigation
					pagination={{ clickable: true }}
					spaceBetween={20}
					slidesPerView={4}
					autoplay={{ delay: 1000, disableOnInteraction: false }}
					loop={true}
					breakpoints={{
						640: { slidesPerView: 2 },
						768: { slidesPerView: 3 },
						1024: { slidesPerView: 4 },
					}}>
					{products.map((product) => (
						<SwiperSlide key={product.id}>
							<div className='product-cart-wrap small hover-up'>
								<div className='product-img-action-wrap'>
									<div className='product-img product-img-zoom'>
										<a href='shop-product-right.html'>
											<img
												className='default-img'
												src={product.img1}
												alt={product.name}
											/>
											<img
												className='hover-img'
												src={product.img2}
												alt={product.name}
											/>
										</a>
									</div>
									<div className='product-action-1'>
										<a
											aria-label='Quick view'
											className='action-btn small hover-up'
											href='#'>
											<i className='fi-rs-eye'></i>
										</a>
										<a
											aria-label='Add To Wishlist'
											className='action-btn small hover-up'
											href='shop-wishlist.html'>
											<i className='fi-rs-heart'></i>
										</a>
										<a
											aria-label='Compare'
											className='action-btn small hover-up'
											href='shop-compare.html'>
											<i className='fi-rs-shuffle'></i>
										</a>
									</div>
									<div className='product-badges product-badges-position product-badges-mrg'>
										<span className={product.badge.toLowerCase()}>
											{product.badge}
										</span>
									</div>
								</div>
								<div className='product-content-wrap'>
									<h2>
										<a href='shop-product-right.html'>{product.name}</a>
									</h2>
									<div className='rating-result' title='90%'>
										<span></span>
									</div>
									<div className='product-price'>
										<span>${product.price.toFixed(2)} </span>
										<span className='old-price'>
											${product.oldPrice.toFixed(2)}
										</span>
									</div>
								</div>
							</div>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</section>
	);
};

export default MonthlyCarousel;
