import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { loginDispatch } from '../reducers/HomeReducer';
import { useNavigate } from 'react-router-dom';

const LoginForm = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	// Validation Schema
	const validationSchema = Yup.object({
		email: Yup.string()
			.email('Invalid email address')
			.required('Email is required'),
		password: Yup.string()
			.min(8, 'Password must be at least 8 characters')
			.required('Password is required'),
		checkbox: Yup.boolean(),
	});

	// Initial Values
	const initialValues = {
		email: '',
		password: '',
		checkbox: false,
	};

	// Form Submission
	const handleSubmit = (values) => {
		console.log('Form Data:', values);
		dispatch(loginDispatch(values, navigate));
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}>
			{({ isSubmitting }) => (
				<Form method='post'>
					<div className='form-group'>
						<Field
							type='text'
							name='email'
							placeholder='Your Email'
							className='form-control'
						/>
						<ErrorMessage
							name='email'
							component='div'
							className='error-message'
						/>
					</div>

					<div className='form-group'>
						<Field
							type='password'
							name='password'
							placeholder='Password'
							className='form-control'
						/>
						<ErrorMessage
							name='password'
							component='div'
							className='error-message'
						/>
					</div>

					<div className='login_footer form-group'>
						<div className='chek-form'>
							<div className='custome-checkbox'>
								<Field
									type='checkbox'
									name='checkbox'
									id='exampleCheckbox1'
									className='form-check-input'
								/>
								<label className='form-check-label' htmlFor='exampleCheckbox1'>
									<span>Remember me</span>
								</label>
							</div>
						</div>
						<a className='text-muted' href='#'>
							Forgot password?
						</a>
					</div>

					<div className='form-group'>
						<button
							type='submit'
							className='btn btn-fill-out btn-block hover-up'
							// disabled={isSubmitting}
						>
							Log in
						</button>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default LoginForm;
