import { axiosInstance } from '../Axios';
import { createSlice } from '@reduxjs/toolkit';
import { storeData } from './UiReducer';

const HomeReducer = createSlice({
	name: 'home',
	initialState: {
		success: false,
		error: null,
		loading: false,
		loginInfo: sessionStorage.getItem('loginInfo')
			? JSON.parse(sessionStorage.getItem('loginInfo'))
			: null,
		user: null,
		forgot: null,
		otp: null,
		reset: null,
		changepassword: null,
		lead: null,
		category: null,
		product: null,
	},
	reducers: {
		loginRequest(state, action) {
			state.loading = true;
		},
		loginSuccess(state, action) {
			state.loading = false;
			state.loginInfo = action.payload;
			storeData('loginInfo', action.payload);
			state.error = null;
		},
		loginFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		userRegistrationRequest(state, action) {
			state.loading = true;
		},
		userRegistrationSuccess(state, action) {
			state.loading = false;
			state.user = action.payload;
			state.error = null;
		},
		userRegistrationFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		forgotRequest(state, action) {
			state.loading = true;
		},
		forgotSuccess(state, action) {
			state.loading = false;
			state.forgot = action.payload;
			state.error = null;
		},
		forgotFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		otpRequest(state, action) {
			state.loading = true;
		},
		otpSuccess(state, action) {
			state.loading = false;
			state.otp = action.payload;
			state.error = null;
		},
		otpFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		resetRequest(state, action) {
			state.loading = true;
		},
		resetSuccess(state, action) {
			state.loading = false;
			state.reset = action.payload;
			state.error = null;
		},
		resetFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		changepasswordRequest(state, action) {
			state.loading = true;
		},
		changepasswordSuccess(state, action) {
			state.loading = false;
			state.changepassword = action.payload;
			state.error = null;
		},
		changepasswordFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		leadRequest(state, action) {
			state.loading = true;
		},
		leadSuccess(state, action) {
			state.loading = false;
			state.lead = action.payload;
			state.error = null;
		},
		leadFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		categoryRequest(state, action) {
			state.loading = true;
		},
		categorySuccess(state, action) {
			state.loading = false;
			state.category = action.payload;
			state.error = null;
		},
		categoryFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		productRequest(state, action) {
			state.loading = true;
		},
		productSuccess(state, action) {
			state.loading = false;
			state.product = action.payload;
			state.error = null;
		},
		productFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
	},
});

const { actions } = HomeReducer;
const config = {
	headers: {
		'Content-Type': 'application/json',
	},
};
const config2 = {
	headers: {
		'Content-Type': 'multipart/form-data',
	},
};
export const {
	loginRequest,
	loginSuccess,
	loginFail,
	userRegistrationRequest,
	userRegistrationSuccess,
	userRegistrationFail,
	forgotRequest,
	forgotSuccess,
	forgotFail,
	otpRequest,
	otpSuccess,
	otpFail,
	resetRequest,
	resetSuccess,
	resetFail,
	changepasswordRequest,
	changepasswordSuccess,
	changepasswordFail,
	leadRequest,
	leadSuccess,
	leadFail,
	categoryRequest,
	categorySuccess,
	categoryFail,
	productRequest,
	productSuccess,
	productFail,
} = actions;

export const loginDispatch = (bodyData, navigate) => async (dispatch) => {
	try {
		dispatch(loginRequest());
		const { data } = await axiosInstance.post(
			'/customer/login',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(loginSuccess(data));
		if (data) {
			navigate('/dashboard');
		} else {
			alert('Failed');
		}
	} catch (error) {
		alert(error.response.data.message);
		dispatch(
			loginFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const userRegistrationDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(userRegistrationRequest());
		const { data } = await axiosInstance.post(
			'/registerpartner',
			bodyData,
			config2
		);
		console.log('data', data);
		dispatch(userRegistrationSuccess(data));
		if (data) {
			alert('Registration Success');
		}
	} catch (error) {
		alert('Registration Failed');
		dispatch(
			userRegistrationFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const forgotPasswordDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(forgotRequest());
		const { data } = await axiosInstance.post('/forgot', bodyData, config);
		console.log('data', data);
		dispatch(forgotSuccess(data));
	} catch (error) {
		dispatch(
			forgotFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const OtpVerifyDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(otpRequest());
		const { data } = await axiosInstance.post(
			'/otp_verification',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(otpSuccess(data));
	} catch (error) {
		dispatch(
			otpFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const resetPasswordDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(resetRequest());
		const { data } = await axiosInstance.post(
			'/reset_password',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(resetSuccess(data));
	} catch (error) {
		dispatch(
			resetFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const changePasswordDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(changepasswordRequest());
		const { data } = await axiosInstance.post(
			'/change_password',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(changepasswordSuccess(data));
	} catch (error) {
		dispatch(
			changepasswordFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const addLeadDispatch = (bodyData, navigate) => async (dispatch) => {
	try {
		dispatch(leadRequest());
		const { data } = await axiosInstance.post('/addlead', bodyData, config);
		console.log('data', data);
		dispatch(leadSuccess(data));
		if (data?.status === 'success') {
			alert('!! Thank you for Interest Our Team will Contact Shortly !!');
			navigate('/');
		} else {
			alert('Failed');
		}
	} catch (error) {
		alert('Failed');
		dispatch(
			leadFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const categoryDispatch = () => async (dispatch) => {
	try {
		dispatch(categoryRequest());
		const { data } = await axiosInstance.get('/categories', config);
		console.log('data', data);
		dispatch(categorySuccess(data));
	} catch (error) {
		alert('Failed');
		dispatch(
			categoryFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const productDispatch = () => async (dispatch) => {
	try {
		dispatch(productRequest());
		const { data } = await axiosInstance.get('/product', config);
		console.log('data', data);
		dispatch(productSuccess(data));
	} catch (error) {
		dispatch(
			productFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export default HomeReducer;
