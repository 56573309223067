import { createSlice } from '@reduxjs/toolkit';
export const storeData = async (key, value) => {
	try {
		const jsonValue = JSON.stringify(value);
		sessionStorage.setItem(`${key}`, jsonValue);
	} catch (e) {
		// saving error
	}
};

export const getData = async (key) => {
	try {
		const jsonValue = sessionStorage.getItem(`${key}`);
		return jsonValue != null ? JSON.parse(jsonValue) : null;
	} catch (e) {
		// error reading value
	}
};
const UiReducer = createSlice({
	name: 'ui',
	initialState: {
		tab: 'men',
		cart: sessionStorage.getItem('cart')
			? JSON.parse(sessionStorage.getItem('cart'))
			: [],
		whishlist: sessionStorage.getItem('whishlist')
			? JSON.parse(sessionStorage.getItem('whishlist'))
			: [],
	},
	reducers: {
		setTab(state, action) {
			state.tab = action.payload;
		},
		addCartRow(state, action) {
			const itemExists = state.cart.some(
				(item) => item.id === action.payload.id
			); // assuming each item has a unique 'id'

			if (!itemExists) {
				state.cart.push(action.payload);
				storeData('cart', state.cart);
			}
		},
		clearCartRow(state, action) {
			state.cart = [];
			storeData('cart', state.cart);
		},
		removeSelectedCart(state, action) {
			state.cart = state.cart.filter((row) => row.id !== action.payload.id); // assuming 'id' is unique
			storeData('cart', state.cart);
		},
		addWhitelistRow(state, action) {
			const itemExists = state.whishlist.some(
				(item) => item.id === action.payload.id
			); // assuming each item has a unique 'id'

			if (!itemExists) {
				state.whishlist.push(action.payload);
				storeData('whishlist', state.whishlist);
			}
		},
		removeSelectedWhish(state, action) {
			state.whishlist = state.whishlist.filter(
				(row) => row.id !== action.payload.id
			); // assuming 'id' is unique
			storeData('whishlist', state.whishlist);
		},
	},
});

const { actions } = UiReducer;

export const {
	setTab,
	addCartRow,
	clearCartRow,
	removeSelectedCart,
	addWhitelistRow,
	removeSelectedWhish,
} = actions;

export default UiReducer;
